@import url(https://fonts.googleapis.com/css?family=Overpass+Mono:400,700|Roboto:400,400i,700&display=swap);
/* font-family: 'Roboto', sans-serif;
font-family: 'Overpass Mono', monospace; */

@-ms-viewport {
  width: device-width;
}

/* https://css-tricks.com/box-sizing/ */
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

@media screen and (max-width: 480px) {
  html,
  body {
    min-width: 320px;
  }
}

html,
body {
  height: 100%;
}

body {
  height: 100vh;
  background-color: white;
  color: #111111;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

body.is-loading *,
body.is-loading *:before,
body.is-loading *:after {
  -webkit-animation: none !important;
  animation: none !important;
  -webkit-transition: none !important;
  transition: none !important;
}

body,
input,
select,
textarea {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2rem;
}

input,
select,
textarea {
  color: #111111;
}

a {
  color: #111111;
}

h1 {
  color: #e5474b;
  font-family: 'Overpass Mono', monospace;
  font-weight: 700;
  font-size: 22px;
  line-height: 2rem;
  margin-bottom: 1rem;
}

h2,
h3,
h4,
h5,
h6 {
  font-family: 'Overpass Mono', monospace;
  font-weight: 700;
  line-height: 1.5rem;

  margin-bottom: 1rem;
}

h2 {
  /* color: #e5474b; */
  font-size: 20px;
}

h3 {
  color: #111111;
  font-size: 18px;
}

h4 {
  color: #111111;
  font-size: 16px;
}

p {
  margin-bottom: 1rem;
  line-height: 1.8rem;
}

strong,
b {
  color: #e5474b;
  font-weight: 700;
}

em {
  font-style: italic;
}

pre {
  overflow: auto;
  border: 1px dashed lightgrey;
  background-color: rgba(123, 123, 123, 0.25);
}

pre,
code {
  font-family: 'Overpass Mono', monospace;
  font-weight: 400;
  line-height: 1.4rem;
}

/* TABLES */
table {
  border: 2px solid #000000;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
table td,
table th {
  border: 1px solid #000000;
  padding: 5px 5px;
}
table tbody td {
  font-size: 14px;
}
table tr:nth-child(even) {
  background: #d0e4f5;
}
table thead {
  background: #cfcfcf;
  background: -webkit-gradient(linear, left top, left bottom, from(#dbdbdb), color-stop(66%, #d3d3d3), to(#cfcfcf));
  background: linear-gradient(to bottom, #dbdbdb 0%, #d3d3d3 66%, #cfcfcf 100%);
  border-bottom: 3px solid #000000;
}
table thead th {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  text-align: left;
}
table tfoot {
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  border-top: 3px solid #000000;
}
table tfoot td {
  font-size: 14px;
}
table tfoot .links {
  text-align: right;
}
table tfoot .links a {
  display: inline-block;
  background: #1c6ea4;
  color: #ffffff;
  padding: 2px 8px;
  border-radius: 5px;
}

